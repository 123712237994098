import React, { useState } from "react"
import { graphql, Link, navigate } from "gatsby"
import { Carousel, Col, Container, Row } from "react-bootstrap"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"



const X = (data) => {
  let projectName = ""; //"projects/1"
  if(data.location && data.location.state && data.location.state.projectName){
    projectName = data.location.state.projectName
  }
  
  let detail_images = [], thumbnail_images = data.data.thumbnail_images.nodes;
  
  data.data.detail_images.nodes.forEach(x => {
    if(x.relativeDirectory === projectName){
      detail_images.push(x)
    }
  })

  const [index, setIndex] = useState(0);

  const handleSelect = (index) => {
    setIndex(index)
  }

  const onSlidCarousel = (index) => {
    //console.log(index, detail_images)
    setIndex(index);
    if(index === detail_images.length - 1){
      //console.log('Go to next project');
      setTimeout(() => {
        for(let i = 0; i < thumbnail_images.length - 1; i++){
          const projectThumbnailName = `projects/${thumbnail_images[i].name}`
          if(projectName === projectThumbnailName){
            setIndex(0);

            if(i < thumbnail_images.length - 2){
              navigate(`/${data.pageContext.folder}/d`, {state: {projectName: `projects/${thumbnail_images[i + 1].name}`}}); //next
            }
            else{
              navigate(`/${data.pageContext.folder}/d`, {state: {projectName: "projects/" + thumbnail_images[0].name}});
            }

            
            break;
          }
        }
        
      }, 2500);
    }
  }
    
  //data.pageContext.pageTitle
    return <Layout pageInfo={{ pageName: "commercial_details", pageTitle: "HARD CODE HERE" }}>
    <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
      {/*
      <div>{
      JSON.stringify(data)
      }</div>
    */}
      <Container className="images_page">


        { 
        //chec has projectName then show control
        projectName !== "" && 
          <Carousel className="mb-4" onSlid={onSlidCarousel} interval={3000} activeIndex={index} onSelect={handleSelect} >
            {detail_images.filter(x=> x.relativeDirectory === projectName).map(x => {
                const image = getImage(x.childImageSharp)

                return <Carousel.Item key={x.id}>
                  <GatsbyImage imgStyle={{maxWidth: '100%'}} image={image} alt={x.name} title={x.name} />
                </Carousel.Item>

              }
            )}
          </Carousel>
        }
        <Row>
          {thumbnail_images.map(x => {
            const image = getImage(x.childImageSharp)
            return <Col style={{marginBottom: '20px'}} key={x.id} xs={6} md={4} lg={3}>
              <Link to={`/${data.pageContext.folder}/d`} state={{projectName: "projects/" + x.name}}>
                <GatsbyImage className="thumbnail" imgStyle={{maxWidth: '100%', maxHeight: '140px'}} image={image} alt={x.name} title={x.name} />
              </Link>
            </Col>
          }
          )}
        </Row>
    </Container>
</Layout>
}

export const Data = graphql`
  query ($folder: String) {
    detail_images: allFile(filter: {sourceInstanceName: {eq: $folder}, relativeDirectory: {ne: "thumbnails"}}, sort: {fields: [name], order: ASC}) {
      nodes {
        id
        name
        relativePath
        sourceInstanceName
        relativeDirectory
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    thumbnail_images: allFile(filter: {sourceInstanceName: {eq: $folder}, relativeDirectory: {eq: "thumbnails"}}, 
    sort: {fields: [name], order: ASC}) {
      nodes {
        id
        name
        relativePath
        sourceInstanceName
        relativeDirectory
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }

`

export default X